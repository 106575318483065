import Cookies from "js-cookie"; 

export const saveUTMParamsToCookies = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const utmParams = {
    utm_source: searchParams.get("utm_source") || Cookies.get("utm_source"),
    utm_medium: searchParams.get("utm_medium") || Cookies.get("utm_medium"),
    utm_campaign: searchParams.get("utm_campaign") || Cookies.get("utm_campaign"),
    utm_term: searchParams.get("utm_term") || Cookies.get("utm_term"),
    utm_content: searchParams.get("utm_content") || Cookies.get("utm_content"),
    gclid: searchParams.get("gclid") || Cookies.get("gclid"), 
    fbclid: searchParams.get("fbclid") || Cookies.get("fbclid"), 
  };

  if (Object.values(utmParams).some((param) => param)) {
    Object.entries(utmParams).forEach(([key, value]) => {
      if (value) {
        Cookies.set(key, value, { expires: 7, path: "/" });
      }
    });
  } else {
    console.log("No UTM parameters found in the URL to save.");
  }
};


export const getUTMParamsFromCookies = () => {
  const keys = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
    "gclid", 
    "fbclid", 
  ];

  const utmParams = keys.reduce((acc: { [key: string]: string }, key) => {
    acc[key] = Cookies.get(key) || "";
    return acc;
  }, {});

  return utmParams;
};
