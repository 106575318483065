import { BackArrow } from "../../assets";
import { forwardRef } from "react";
import { useNavigation } from "./NavigationContext";
import Logo from '../../assets/mwm/mwl-logo.png'

const Header = forwardRef<HTMLDivElement>((props, ref) => {
  const { goBack } = useNavigation();

  return (
    <div ref={ref} className="flex z-30 justify-between xs:relative top-0 w-full items-center xs:px-[2rem] xs:pt-[0.75rem] md:px-[2rem] lg:px-[5rem] 3xl:pt-4 xl:pt-4 bg-white">
      {/* Back Button */}
      <button onClick={goBack} className="flex items-center">
        <BackArrow />
      </button>

      {/* Centered Logo and Branding */}
      <div className="flex flex-col items-center flex-1">
        <img src={Logo} alt="Modern Weight Loss" className="xs:h-[94px] xs:w-[94px] xs:mb-[0.5rem] flex items-center" />
      </div>
    </div>
  );
});

export default Header;
