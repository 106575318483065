import { useDispatch } from "react-redux";
import "./App.css";
import { AppRoutes } from "./AppRoutes";
import Footer from "./components/macrocomponents/Footer";
import Header from "./components/macrocomponents/Header";
import Progress from "./components/microcomponents/Progress";
import { useEffect, useCallback, useRef, useState } from "react";
import { setFormReset } from "./lib/store/actions/formAction";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "./hooks/ScrollToTop";
import useAvailableDimension from "./hooks/UseAvailableDimension";
import { NavigationProvider } from "../src/components/macrocomponents/NavigationContext";
import useKeyboardAdjust from "./hooks/SafariHook";
import { saveUTMParamsToCookies } from "../src/utils/UTMparams";
import packageJson from "../package.json";
const BUILD_VERSION = packageJson.version; 


const SESSION_KEY = "sessionData";
const EXPIRATION_TIME = 1225 * 60 * 1000;

function App() {
  useKeyboardAdjust();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [isFooterFixed, setIsFooterFixed] = useState(true);

  const startNewSession = useCallback(() => {
    const sessionStartTime = new Date().getTime();
    localStorage.setItem(SESSION_KEY, JSON.stringify({ startTime: sessionStartTime }));
  }, []);

  const clearSession = useCallback(() => {
    localStorage.removeItem(SESSION_KEY);
    dispatch(setFormReset());
    navigate("/", { replace: true });
    startNewSession();
  }, [dispatch, navigate, startNewSession]);

  const checkSessionExpiration = useCallback(() => {
    const sessionData = JSON.parse(localStorage.getItem(SESSION_KEY) || "{}");
    const currentTime = new Date().getTime();

    if (sessionData && sessionData.startTime) {
      const timeElapsed = currentTime - sessionData.startTime;
      if (timeElapsed >= EXPIRATION_TIME) {
        clearSession();
      } else {
        setTimeout(clearSession, EXPIRATION_TIME - timeElapsed);
      }
    } else {
      startNewSession();
    }
  }, [clearSession, startNewSession]);

  useEffect(() => {
    checkSessionExpiration();
  }, [checkSessionExpiration]);

  useEffect(() => {
    saveUTMParamsToCookies();
  }, []);

  useEffect(() => {
    const storedVersion = localStorage.getItem("appVersion");
    if (!storedVersion) {
      localStorage.setItem("appVersion", BUILD_VERSION);
    } else if (storedVersion !== BUILD_VERSION) {
      dispatch(setFormReset());
      localStorage.setItem("appVersion", BUILD_VERSION);
    }
  }, [dispatch]);

  const { availableHeight, availableWidth, fixedHeight, headerRef, footerRef, progressBarRef } = useAvailableDimension();

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      setIsFooterFixed(contentHeight <= availableHeight);
    }
  }, [availableHeight]);

  return (
    <NavigationProvider>
      <div className="w-full h-dvh bg-linear-background overflow-x-hidden overflow-y-auto min-h-screen">
        <ScrollToTop />
        <Header ref={headerRef} />
        <div ref={contentRef} className="flex flex-1 flex-col scroll-smooth" 
        style={{minHeight: availableHeight}}>
          <Progress ref={progressBarRef} />
          <AppRoutes availableHeight={availableHeight} marginTop={fixedHeight} availableWidth={availableWidth} />
        </div>
        <Footer ref={footerRef} />
      </div>
    </NavigationProvider>
  );
}

export default App;
