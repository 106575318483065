import hipaa from "../../assets/PCABGoldSteal.webp";
import seal from "../../assets/SealofCompliance.webp";
import { forwardRef, useMemo } from "react";
const Footer = forwardRef<HTMLDivElement>((props, ref) => {
  const legitScriptUrl = "https://www.legitscript.com/websites/?checker_keywords=modernweightloss.com";
  
  // Memoizing the LegitScript image URL to avoid unnecessary re-renders
  const imageUrl = useMemo(
    () => `${window.location.protocol}//static.legitscript.com/seals/31572174.png`,
    []
  );

  // Define shared styles
  const imgClass = "xs:h-12 2xl:h-[4rem] lg:h-[3rem] w-auto";

 
    return (
      <footer
        ref={ref}
        className=" w-full bg-[#FFFFFF] md:flex justify-between items-center md:px-6 xl:px-[5rem] 2xl:py-4 xs:my-4 lg:my-0"
      >
      <div className="grid xs:grid-cols-1 md:grid-cols-2 md:flex-row space-x-4 w-full my-2">
        {/* Seals Section */}
        <div className="flex gap-6 xs:justify-center xs:items-center md:items-start md:justify-start xs:w-full xs:pb-[20px] md:pb-0">
          <img src={seal} alt="PCAB Gold Seal" className={imgClass} aria-label="PCAB Accreditation Seal" />
          <img src={hipaa} alt="HIPAA Compliance Seal" className={imgClass} aria-label="HIPAA Compliance Seal" />
          <a href={legitScriptUrl} target="_blank" rel="noopener noreferrer" title="Verify LegitScript Approval">
            <img src={imageUrl} alt="LegitScript Approved Seal" className={imgClass} aria-label="LegitScript Approval Seal" />
          </a>
        </div>

        {/* Title Section */}
        <div className="flex items-center md:justify-end xs:justify-center">
        <h1 className="text-[#333333] font-normal text-center md:text-right 2xl:text-[1.5rem] md:text-[1rem]">
          The <span className="font-bold 2xl:text-[1.5rem] md:text-[1rem] mt-2">Modern Weight Loss </span>
          <span className="text-[#008080] font-turbinado font-[500] 2xl:text-[2.5rem] md:text-[1.5rem]">Difference</span>
        </h1>
        </div>
        
      </div>
    </footer>
  );
});

export default Footer;