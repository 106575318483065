import React from "react";
import { RoutePath } from "../../AppRoutes";
import { useLocation } from "react-router-dom";

const progressPercentage: Record<string, string> = {
  [RoutePath.home]: "1%",
  [RoutePath.glp]: "5%",
  [RoutePath.info]: "10%",
  [RoutePath.greetings]: "14%",
  [RoutePath.motivation]: "19%",
  [RoutePath.weHearYou]: "24%",
  [RoutePath.BMICalculator]: "29%",
  [RoutePath.greatNews]: "33%",
  [RoutePath.currentMedications]: "38%",
  [RoutePath.medicalHistory]: "43%",
  [RoutePath.organHealth]: "48%",
  [RoutePath.glp1Question]: "52%",
  [RoutePath.medicationsInput]: "57%",
  [RoutePath.allergyinput]: "62%",
  [RoutePath.pregnancy]: "70%",
  [RoutePath.thanksForSharing]: "75%",
  [RoutePath.initiatives]: "80%",
  [RoutePath.idProofUpload]: "85%",
  [RoutePath.consent]: "90%",
  [RoutePath.qualified]: "95%",
  [RoutePath.success]: "100%",
  [RoutePath.disqualified]: "100%",
};

interface ProgressProps {
  percentage?: string;
}

const Progress = React.forwardRef<HTMLDivElement, ProgressProps>(({ percentage }, ref) => {
  const location = useLocation();
  const path = location.pathname;
  const progressString = percentage || progressPercentage[path] || "1%";
  const progressValue = parseFloat(progressString); 
  const isSmallProgress = progressValue <= 10;

  return (
    <div className="h-2 bg-gray-300 xs:mt-[1rem] md:[2.75rem] 2xl:mt-3 relative">
      <div
        className="h-2 rounded-full relative bg-[#78C5D2]"
        style={{
          width: progressString,
          background: ` ${progressValue}%)`,
        }}
      >
        <span
          className={`absolute text-[#376C74] whitespace-nowrap text-xs ${
            isSmallProgress ? "right-[-1.5rem]" : "right-0"
          }`}
          style={{ bottom: "6px" }}
        >
          {progressString}
        </span>
      </div>
    </div>
  );
});

export default Progress;