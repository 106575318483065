import { SET_FORM_FIELD, SET_FORM_RESET, FormActionTypes } from "../actions/actionTypes";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  dob: "",
  gender: "",
  paymentDescription: "Payment for GLP-1 meds",
  productBundleId: "f1d0070b-6b27-4822-a668-f5b0adb38d61",
  paymentAmount: 80.0,
  shippingAddress: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "US",
    postalCode: "",
  },
  formTitle: "Modern Weight Loss Form intake",
  formDescription: "Modern Weight Loss",
  promoCodes: [{ Promo: "Lose10" }],
  questions: [
    {
      question: "What is the clientPatientID",
      answer: "",
      type: "TEXT",
    },
    {
      question: "What is your current height and weight and BMI?",
      answer: [],
      phi: true,
      type: "TEXT",
    },
    {
      question: "Please check all current or past medical conditions?(CurrentConditions)",
      options: [
        "Gastroparesis",
        "Pancreatic Cancer",
        "Pancreatitis",
        "Type 1 Diabetes or Diabetes Requiring Insulin",
        "Hypoglycemia",
        "Medullary Thyroid Cancer (MTC) or family history of MTC",
        "Bipolar Disorder",
        "Schizophrenia",
        "Multiple Endocrine Neoplasia syndrome type 2 (MEN-2) or family history of MEN-2",
        "Anorexia or Bulimia",
        "Current Symptomatic Gallstones or Active Gallbladder Disease",
        "Active Substance Abuse Disorder",
      ],
      type: "MULTISELECT",
      answer: [],
    },
    {
      question: "Please check all current or past medical conditions?(MedicalHistory)",
      options: [
        "Hypertension (high blood pressure)",
        "High cholesterol",
        "Type 2 diabetes",
        "Obstructive sleep apnea",
        "Gout",
        "Metabolic syndrome",
        "Heart disease, stroke, or peripheral vascular disease",
        "Heart Failure",
        "Atrial fibrillation or flutter",
        "Tachycardia or fast heart rate",
        "Any ECG abnormality or heart rhythm abnormality",
      ],
      type: "MULTISELECT",
      answer: [],
    },
    {
      question: "Please check all current or past medical conditions?(OrganHealth)",
      options: [
        "Gallbladder removed",
        "Fatty Liver (MASLD or MASH)",
        "Cirrhosis or end-stage liver disease",
        "Chronic Kidney Disease Stage 3 or greater",
        "Hypothyroidism, Hyperthyroidism, or Thyroid Issues",
      ],
      type: "MULTISELECT",
      answer: [],
    },
    {
      question: "Please list all your current medications, including prescription, over-the-counter, and supplements, including dosages.",
      answer: [],
      phi: true,
      type: "TEXT",
    },
    {
      question: "Are you currently taking a GLP-1",
      answer: [],
      options: ["Yes", "No"],
      type: "SINGLESELECT",
    },
    {
      question: "Are you currently pregnant, breastfeeding, or planning to become pregnant within the next 2 months?",
      answer: "No",
      options: ["Yes", "No"],
      type: "SINGLESELECT",
    },
    {
      question: "What is your weight loss goals?",
      options: ["Lose 1-20 lbs for good", "Lose 21-50 lbs for good", "Lose over 50 lbs for good", "Maintain my healthy weight", "None of the above", "Other"],
      answer: [],
      type: "SINGLESELECT",
    },
    {
      question: "What weight-loss initiatives have you tried in the past?",
      options: ["Exercise", "Dieting", "Weight-loss Supplements", "Intermittent Fasting", "Other GLP1's", "Other"],
      answer: [],
      type: "SINGLESELECT",
    },
    {
      question: "Please list all of your known allergies?",
      answer: [],
      phi: true,
      type: "TEXT",
    },
    {
      question: "If yes, please upload proof of your current prescription",
      type: "FILE",
      answer: [
        {
          name: "",
          contentType: "application/pdf",
          data: "",
        },
      ],
    },
    {
      question: "Please upload a government issued form of ID (Driver's License, Passport, etc).",
      type: "FILE",
      answer: [
        {
          name: "",
          contentType: "application/pdf",
          data: "",
        },
      ],
    },
  ],
};

const formReducer = (state = initialState, action: FormActionTypes): typeof initialState => {
  switch (action.type) {
    case SET_FORM_FIELD:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case SET_FORM_RESET:
      return initialState;
    default:
      return state;
  }
};

export default formReducer;
